/* global process */
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { SliceZone } from '../components';
import { linkResolver, transformFieldName } from '../utils/prismic-config';

function Homepage ({ data }) {
  const doc = data.prismicHomepage.data;

  return (
    <>
      <Helmet>
        <title>{doc.title.text}</title>
        <meta name="og:title" content={doc.title.text} />
        <meta name="description" content={doc.metaDescription?.text} />
        <meta name="og:description" content={doc.metaDescription?.text} />
        {/*<meta name="og:image" content={ogImage?.fixed?.src} />*/}
      </Helmet>
      <SliceZone sliceZone={doc.body} url="/" />
    </>
  );
}

export const query = graphql`
query HomepageQuery {
  prismicHomepage {
    _previewable

    data {
      title { text }
      metaDescription
      body {
        __typename

        ... on PrismicHomepageDataBodyHero {
          ...homepageHeroFields
        }
        ... on PrismicHomepageDataBodyEndToEnd {
          ...homepageEndToEndFields
        }
        ... on PrismicHomepageDataBodyRelatedArticles {
          ...homepageRelatedArticlesFields
        }
        ... on PrismicHomepageDataBodyServicesOverview {
          ...homepageServicesOverviewFields
        }
        ... on PrismicHomepageDataBodySignupForm {
          ...homepageSignupFormFields
        }
        ... on PrismicHomepageDataBodySliderSection {
          ...homepageSliderSectionFields
        }
        ... on PrismicHomepageDataBodyCustomCode {
          ...homepageCustomCodeFields
        }
      }
    }
  }
}
`;

export default withPrismicPreview(Homepage, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver, transformFieldName
  }
]);
